import React, { useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';

const AnimatedText = (props) => {
  const headingRef = useRef(null);

  // Animation for fading out

  return (
    <div>
      <Fade>
        <h2 ref={headingRef}>{props.title}</h2>
      </Fade>
      <Fade>
        <div className="title-underline"></div>
      </Fade>
    </div>
  );
};

export default AnimatedText;

import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { animated } from 'react-spring';
import NeuRoundButton from '../Buttons/NeuRoundButton';
import laugh from '../../images/laughlin.webp';
import Typewriter from 'typewriter-effect';

const ContactModal = ({ style, closeModal }) => (
  <>
    <div className="modal-backdrop" onClick={closeModal}></div>
    <animated.div style={style} className="modal">
      <Row style={{ alignItems: 'center' }}>
        <Col xs={10}>
          <Image src={laugh} fluid style={{ width: 50 }} />
        </Col>
        <Col xs={2} style={{ paddingLeft: 0 }}>
          <NeuRoundButton onClick={closeModal} />
        </Col>
      </Row>
      <h3 className="contact-modal-title">Hah! Got'em</h3>
      <h5 className="contact-modal-subtitle">
        <Typewriter
        options={{cursor: '', deleteSpeed: 100}}
          onInit={(typewriter) => {
            typewriter
              .changeDelay(24)
              .typeString('Lolololololol!')
              .deleteAll()
              .typeString('You got trolled!')
              .pauseFor(1000)
              .deleteAll()
              .typeString('By an uncontrolled input')
              .pauseFor(1000)
              .deleteAll()
              .typeString('Deal with it')
              .start();
          }}
        />
      </h5>
    </animated.div>
  </>
);

export default ContactModal;

import React, { useState, useEffect } from 'react';

const NeuRoundButton = (props) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <button className="neu-btn" {...props}>
      {props.svgicon ? (
        props.svgicon
      ) : (
        // <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        // <path d="M11.7568 20.2415L20.2421 11.7562" stroke="#fefefe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M18.1206 22.3638L14.5851 25.8993C13.4598 27.0242 11.9337 27.6561 10.3426 27.6559C8.75151 27.6558 7.22559 27.0237 6.1005 25.8986C4.97541 24.7735 4.34328 23.2476 4.34314 21.6565C4.343 20.0653 4.97487 18.5393 6.09977 17.414L9.63531 13.8785" stroke="#fefefe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M22.3638 18.1205L25.8994 14.585C27.0243 13.4597 27.6561 11.9337 27.656 10.3426C27.6559 8.75145 27.0237 7.22554 25.8986 6.10045C24.7735 4.97535 23.2476 4.34322 21.6565 4.34309C20.0654 4.34295 18.5394 4.97482 17.4141 6.09972L13.8785 9.63525" stroke="#fefefe" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        // </svg>
        <svg
        id="close"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 20.964 20.964"
      >
        <path
          id="close-2"
          data-name="close"
          d="M6.91,4.929A1.4,1.4,0,0,0,4.929,6.91L13.019,15l-8.09,8.09A1.4,1.4,0,1,0,6.91,25.071L15,16.981l8.09,8.09a1.4,1.4,0,0,0,1.981-1.981L16.981,15l8.09-8.09A1.4,1.4,0,0,0,23.09,4.929L15,13.019Z"
          transform="translate(-4.518 -4.518)"
          fill="#fff"
        />
      </svg>

        
      )}
    </button>
  );
};

export default NeuRoundButton;

import React, { useState, useEffect } from 'react';

const EdgeLink = (props) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <a
      href={props.hasHref ? props.hasHref : '#'}
      className="edge-link"
      download={props.isDownload ? true : false}
      target="_blank"
    >
      {props.text}
      <span className="edge-link-icon">
        <svg
          id="arrow-top-right"
          xmlns="http://www.w3.org/2000/svg"
          width="11.051"
          height="11.01"
          viewBox="0 0 13.051 13.01"
        >
          <path
            id="arrow-top-right-2"
            data-name="arrow-top-right"
            d="M10.525,5.495l0,2,4.558.011-9.6,9.584,1.413,1.416,9.63-9.61-.011,4.614,2,0,.019-8Z"
            transform="translate(-5.475 -5.495)"
            fill="#d9d9d9"
          />
        </svg>
      </span>
    </a>
  );
};

export default EdgeLink;

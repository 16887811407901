import React, { useContext, useEffect, useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import { useTransition } from 'react-spring';
import gsap from 'gsap';
import { useIntersection } from 'react-use';
import AnimatedText from '../AnimatedText/AnimatedText';
import ContactInput from '../ContactInput';
import EdgeLink from '../EdgeLink';
import ContactModal from '../ContactModal';

const Contact = () => {
  const { projects } = useContext(PortfolioContext);
  const [modalVisible, setModalVisible] = useState(false);
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // All the ref to be observed
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  });

  //Modal Transitions
  const transitions = useTransition(modalVisible, null, {
    from: { opacity: 0, transform: 'translateY(-30px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(30px)' },
  });

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  // Animation for fading in
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: -60,
      ease: 'power4.out',
      stagger: {
        amount: 0.8,
      },
    });
  };
  // Animation for fading out
  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: 'power4.out',
      stagger: {
        amount: 0.8,
      },
    });
  };

  return (
    <section id="contacts" ref={sectionRef}>
      <Container className="px-0">
        <div className="contacts-wrapper">
          <div className="section-title">
            <AnimatedText title="Connect" />
          </div>

          <div className="contacts-content pb-5">
            <Row>
              <Col lg={6}>
                <Fade bottom duration={800} delay={600} distance="50px">
                  <div className="contact-desc mb-5">
                    <p>
                      A human 🎮 who ❤️ to convert chai ☕ to code 💻 and chips 🍟 to design 🎨. I
                      focus on creating <span className="grad-link">elegant brand experiences</span>{' '}
                      and products that redefine <span className="grad-link">functionality</span>{' '}
                      and <span className="grad-link">interactivity</span> at its finest level.
                    </p>
                  </div>
                </Fade>
                <Fade bottom={true} duration={800} delay={1000} distance="40px">
                  <div className="mt-5">
                    <ContactInput
                      onSubmitButtonPress={() => setModalVisible(true)}
                      value=''
                      onChange={() => {}}
                      placeHolder="your@email.com"
                    />
                  </div>
                </Fade>
                <Fade bottom duration={800} delay={1300} distance="30px">
                  <div className="contact-desc mt-5">
                    <p>
                      You can reach out to me at:
                      <span className=""> <a href="mailto:zabi@progton.com" className='blog-grad-link'>zabi@progton.com</a></span>
                    </p>
                  </div>
                </Fade>

                <Fade bottom duration={800} delay={1600} distance="20px">
                  <div className="pt-5">
                    <EdgeLink text="Download resume" hasHref="./new-resume.pdf" />
                  </div>
                </Fade>
              </Col>
            </Row>
            
            {transitions.map(
              ({ item, key, props: style }) =>
                item && <ContactModal style={style} closeModal={() => setModalVisible(false)} key={key} />
            )}
            {/* <CoffeeBlock /> */}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;

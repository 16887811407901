import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const StrokedLink = (props) => {
  return (
    <div className="mt-4 mt-md-4 pt-3 w-100 text-left stroked-link d-flex justify-content-start">
      <div>
        <TransitionLink exit={props.exit} entry={props.entry} className="stroked-text" to="/works">
          Explore all works
        </TransitionLink>
      </div>

      <div className="center-con ml-5">
        <div id="cta">
          <span className="arrow primera next bounceAlpha"></span>
          <span className="arrow segunda next bounceAlpha"></span>
        </div>
      </div>
    </div>
  );
};

export default StrokedLink;

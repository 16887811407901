import React from 'react';
import { Button } from 'react-bootstrap';

const ContactInput = (props) => {
  const { placeHolder, value, onChange, onSubmitButtonPress } = props;
  return (
    <div className="contact-input">
      <input className="input-custom" value={value} onChange={onChange} placeholder={placeHolder} />
      <Button variant='light' className='ghost-btn contact-form-icon-btn' onClick={onSubmitButtonPress}><svg
          id="arrow-top-right"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 13.051 13.01"
        >
          <path
            id="arrow-top-right-2"
            data-name="arrow-top-right"
            d="M10.525,5.495l0,2,4.558.011-9.6,9.584,1.413,1.416,9.63-9.61-.011,4.614,2,0,.019-8Z"
            transform="translate(-5.475 -5.495)"
            fill="#d9d9d9"
          />
        </svg></Button>
    </div>
  );
};

export default ContactInput;

import React, { useEffect, useState } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const WorksCard = (props) => {

  return (
    <div className="works-card-default">

      <div className="works-card-content">

        <TransitionLink className="works-card-image-surface" to={props.to} exit={props.exit} entry={props.entry}>
          <img className="hover-images d-none" src={props.imgOne} alt="img alternative" />
          <img className="hover-images d-none" src={props.imgTwo} />
        </TransitionLink>
        <div className="works-card-title">
          <TransitionLink className='mini-link' to={props.to} exit={props.exit} entry={props.entry}>
            <span className="mask">
              <div className="link-container">
                <span className="link-title1 title">{props.workTitle}</span>
                <span className="link-title2 title">{props.workTitle}</span>
              </div>
            </span>

          </TransitionLink>
        </div>
      </div>
      <div className="works-card-side-text">
        <p className="tilted-text">
          {props.tagOne} &emsp; / &emsp; {props.tagTwo} &emsp;
          {props.tagThree && `/`} &emsp; {props.tagThree && `${props.tagThree}`}
        </p>
      </div>
    </div>
  );
};

export default WorksCard;

import React, { useContext, useEffect, useState, useRef } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Splitting from 'splitting';
import hoverEffect from 'hover-effect';

import WorksCard from '../WorksCard';
import gsap from 'gsap';
import { useIntersection } from 'react-use';
import AnimatedText from '../AnimatedText/AnimatedText';
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link';
import DisplacementImage from '../../images/fog.png';
import StrokedLink from '../StrokedLink';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);
  const sectionRef = useRef(null);
  const cardsRef = useRef([]);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const layoutContents = useRef();
  const transitionCover = useRef();
  // All the ref to be observed
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    // Animation for fading out
    Splitting({
      /* target: String selector, Element, Array of Elements, or NodeList */
      target: '[data-splitting]',
      /* by: String of the plugin name */
      by: 'chars',
      /* key: Optional String to prefix the CSS variables */
      key: null,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      animateHoverCards();
    }, 200);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const animateHoverCards = () => {
    const cards = document.querySelectorAll('.works-card-image-surface');
    cards.forEach(
      (x) =>
        new hoverEffect({
          parent: x,
          intensity: 0.2,
          image1: x?.childNodes[0]?.src,
          image2: x?.childNodes[1]?.src,
          displacementImage: DisplacementImage,
          speedIn: 1.4,
          speedOut: 0.9,
          imagesRatio: 836 / 1254,
        })
    );
  };



  // Animation for fading in
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: -60,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };

  // Animation for fading out
  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: 'power4.out',
      stagger: {
        amount: 1,
      },
    });
  };

  const verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === 'up' ? '-100%' : '100%';
    const directionFrom = direction === 'up' ? '100%' : '-100%';

    // convert ms to s for gsap
    const seconds = length;

    return gsap
      .timeline()
      .set(transitionCover, { y: directionFrom })
      .to(transitionCover, {
        y: '0%',
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      })
      .set(layoutContents, { opacity: 0 })
      .to(transitionCover, {
        y: directionTo,
        ease: 'power1.easeIn',
        duration: seconds / 2,
      });
  };

  const exitHorizontal = ({ length }, direction) => {
    const seconds = length;

    const directionTo = direction === 'left' ? '-100%' : '100%';
    const directionFrom = direction === 'left' ? '100%' : '-100%';

    return gsap
      .timeline()
      .set(transitionCover.current, { x: directionFrom, display: 'block' })
      .to(transitionCover.current, {
        x: '0%',
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      })
      .set(layoutContents.current, { opacity: 0 })
      .to(transitionCover.current, {
        x: directionTo,
        ease: 'power1.easeInOut',
        duration: seconds / 2,
      });
  };

  // checking to see when the vieport is visible to the user
  // intersection && intersection.intersectionRatio < 0.2
  //   ? fadeOut('.card-container')
  //   : fadeIn('.card-container');
 

  return (
    <section id="projects" ref={sectionRef}>
      <Container className="px-0">
        <div className="project-wrapper">
          <div className="section-title">
            <AnimatedText title={'Works'} />
          </div>

          <div className="works-grid">
            {projects.map((project, index) => {
              const {
                title,
                info,
                info2,
                url,
                repo,
                imgOne,
                imgTwo,
                id,
                to,
                tagOne,
                tagTwo,
                tagThree,
              } = project;

              return (
                <div className="card-container" key={id} ref={cardsRef}>
                  <WorksCard
                    to={`/works/${to}`}
                    exit={{
                      length: 1,
                      trigger: ({ exit }) => exitHorizontal(exit, 'left'),
                    }}
                    entry={{
                      delay: 0.5,
                    }}
                    workTitle={title}
                    tagTwo={tagOne}
                    tagOne={tagTwo}
                    imgOne={imgOne}
                    imgTwo={imgTwo}
                    tagThree={tagThree}
                  />
                </div>
              );
            })}
          </div>

          <StrokedLink
            exit={{
              length: 1,
              trigger: ({ exit }) => exitHorizontal(exit, 'left'),             
            }}
            entry={{
              delay: 1, 
            }}
          />
        
        </div>
      </Container>
      <TransitionPortal>
        <div
          ref={transitionCover}
          style={{
            position: 'fixed',
            background: '#131313',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'none',
          }}
        />
      </TransitionPortal>
    </section>
  );
};

export default Projects;
